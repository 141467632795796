import * as _ from "underscore";
import {

  SourceTypesEnum,
} from "../@types/globals";


interface LowestPriceMultipleInput {
  source_type?: string;
  price: number | null;
}

function getLowestPriceMultiple<T>(
  prices: null | ReadonlyArray<T & (LowestPriceMultipleInput | null)>,
  filterOnline = false
) {
  if (!prices || prices.length === 0) {
    return [];
  }
  // group price objects by price
  const processedPrice = filterOnline
    ? _.filter(prices, (price: { source_type?: string | null } | null) => {
        return price?.source_type !== SourceTypesEnum.ONLINE;
      })
    : prices;
  const groupedPrices = _.groupBy(processedPrice, "price");

  // sort price keys by cheapest
  const sortedPrices = _.sortBy(_.keys(groupedPrices), (p) => parseFloat(p));
  const cheapestPrice = _.first(sortedPrices);

  if (!cheapestPrice) {
    return [];
  }
  return groupedPrices[cheapestPrice];
}



export default {
  getLowestPriceMultiple,
};
