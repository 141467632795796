export interface ICategory {
  id: string;
  public_id: string;
  color: string;
  icon_url: string;
  name: string;
  parent: {
    id: string;
    name: string;
  };
  childNodes?: ICategory[];
}

export interface IMinisite {
  id: string;
  title: string;
  sub_title: string;
  url: string;
  primary_color: string;
  secondary_color: string;
  title_color: string;
}

export interface IFavoriteProducts {
  id: string;
  products: IProduct[];
}

export interface IRetailer {
  id: string;
  name: string;
  logo: IRetailerLogo;
  channel: string;
  is_visible: boolean;
  sources: ISource[];
}

export interface IRetailerStore {
  id: string;
  retailer: IRetailer;
  name: string;
  address1: string;
  address2: string;
  postcode: string;
  city: string;
  state: string;
  country: string;
  latitude: number;
  longitude: number;
}

export interface IRetailerLogo {
  small: string;
  large: string;
  center: string;
}

export interface NotificationPayload {
  type: string; // tslint:disable-line no-reserved-keywords
  title: string;
  message: string;
  image?: string;
  action_url?: string;
  action_id?: string;
  size?: string;
  notification_id?: string;
}

export interface ISource {
  id: string;
  pages: ISourcePage[];
  publication_date: string;
  retailer: IRetailer;
}

export interface ISourcePage {
  id: string;
  page: number;
  small: string;
  large: string;
}

export interface IBrand {
  id: string;
  name: string;
  logo_url?: string;
  type?: string;
  status?: string;
}

export interface IButton {
  id: string;
  title: {
    en?: string;
    ms?: string;
    zh?: string;
  };
  color?: string;
  image_url: string;
  location: string;
  position: string;
  action_type: string;
  action_id: string;
  action_url: string;
}

export interface IUser {
  id: string;
  email: string;
  name: string;
  location_name: string;
  geoloc: {
    latitude: number;
    longitude: number;
  };
  postcode: string;
  properties: [{ key: string; value: string }];
}

export interface IRegion {
  id: number;
  name: string;
  country: string;
}

export interface INavigation {
  state: {
    params: {
      screen?: string;
      mode?: string;
      item?: any;
      retailer?: IRetailer;
    };
  };
  navigate(route: string): void;
}

export interface IMessage {
  id: string;
  sender: string;
  recipient: string;
  type: string;
  title: string;
  message: string;
  action: string;
  gallery: GalleryItem[];
  media_url: string | null;
  notification_id: string;
  created_at: string;
}

export interface GalleryItem {
  id: string;
  description: string;
}

export interface IArticle {
  id: string;
  title: string;
  description: string;
  created_at: string;
  image: string;
  url: string;
  category: IArticleCategory;
  source_url: string | null;
}

export interface IArticleCategory {
  id: string;
  name: string;
  icon_url: string;
  color: string;
}

export interface ILayout {
  id: string;
  name: string;
  screen: string;
  data: string;
  is_active: boolean;
}

export interface IVoucher {
  id: string;
  client: string;
  name: {
    en: string;
    ms: string;
    zh: string;
  };
  description: {
    en: string;
    ms: string;
    zh: string;
  };
  conditions: {
    en: string;
    ms: string;
    zh: string;
  };
  expiry: string;
  location: string;
  type: string;
  is_unique: boolean;
  region: IRegion;
  audiences: IAudience[];
  url: string;
  terms: string;
  header: string;
  thumbnail: string;
  code: IVoucherCode | null;
  voucher_code_count: number;
  voucher_code_remaining: number;
}

export interface IVoucherCode {
  id: string;
  code: string;
  claimed_at: string;
  used_at: string;
}

export interface IAudience {
  name: string;
}

export interface ILayoutData {
  items: ILayoutItem[];
}

export interface IPopup {
  id: string;
  title: string;
  popup_image: string;
  popup_bubble: string;
  action_type: string;
  action_value: string;
  valid_from: string;
  valid_till: string;
}

export interface IPopup {
  id: string;
  title: string;
  popup_image: string;
  popup_bubble: string;
  action_type: string;
  action_value: string;
  valid_from: string;
  valid_till: string;
}

export interface ILayoutItem {
  type: string;
  id?: string | { [key: string]: string };
  ids?: number[] | string[];
  image?: {
    [locale: string]: string;
  };
  colors?: {
    category1?: string[];
    category2?: string[];
    category3?: string[];
    category4?: string[];
    title_color?: string;
    background_color1?: string;
    background_color2?: string;
    attention_color?: string;
  };
  limit?: string;
  valid_from?: string;
  valid_till?: string;
}

export interface IMustBuyItem {
  id: string;
  product: IProduct;
}

export interface ICarouselItem {
  id: string;
  name: string;
  image_url?: string;
  position: number;
  cta_type: string;
  cta_value: string;
  action_url: string;
}

export interface IBanner {
  id: string;
  locale: string;
  image_url: string | null;
  name: string;
  description: string;
  valid_from: string;
  valid_till: string;
  action_text: string;
  action_color: string;
  action_type: string;
  action_value: string;
  action_url: string;
  popup_title?: string;
  popup_content?: string;
  products?: IProduct[] | null;
}

export interface IEvent {
  id: string;
  name: { [locale: string]: string | undefined };
  start_date: string;
  end_date: string;
  retailers: IRetailer[];
  products: IProduct[];
  title_color: string;
  background_color: string;
  subheader_color: string;
  attention_color: string;
}

export interface ISpecial {
  id: string;
  locale: string;
  title: { [locale: string]: string | undefined };
  subheader: { [locale: string]: string | undefined };
  secondary_color: string;
  variant: number;
  attention_color: string;
  gradient: string[];
  title_color: string;
  region_id: number;
  products: IProduct[];
  custom_products: ICustomProduct[];
  valid_from: string;
  valid_till: string;
  retailer?: IRetailer; // deprecated
  retailers?: IRetailer[];
  promo_start?: string;
  background_image?: string;
  tagline_text?: {
    en: string;
    ms: string;
    zh: string;
  };
  tagline_text_color?: string;
  tagline_bg_color?: string;
  tab_text?: {
    en: string;
    ms: string;
    zh: string;
  };
  tab_text_color?: string;
  tab_bg_color?: string;
  comparison_retailers?: IRetailer[];
  highlight_color?: string;
  logo?: string;
  audiences?: string[];
}

// enum NotificationType {
//     Basic = 'basic',
//     Admin = 'admin',
//     Mailer = 'mailer',
//     Price = 'price',
//     Annoucement = 'annoucement',
// }

export interface INotificationPayload {
  type: string;
  url: string | null;
  id: string | null;
}

export interface IUserProperty {
  key: string;
  value: string;
}

export interface IProduct {
  is_alert?: IPriceAlert;
  id: string;
  name: string;
  short_name: string;
  slug: string;
  image: {
    small: string;
    large: string;
  };
  categories: ICategory[];
  countries: string[];
  keywords: string[];
  prices: IProductPrice[];
  price_summary: {
    currency: string;
    low: number;
    mean: number;
    high: number;
    mode: number;
  };
  sort_key_name: string;
  is_favorite: boolean;
  is_new: boolean;
  is_mustbuy: boolean;
  sponsored_until?: string;
  highlightedText?: string;
}

export interface IPriceAlert {
  id: string;
  product: IProduct;
  price: number;
  region: IRegion;
}

export enum SourceTypesEnum {
  INSTORE = 'INSTORE',
  MAILER = 'MAILER',
  MANUAL = 'MANUAL',
  NEWSPAPER = 'NEWSPAPER',
  ONLINE = 'ONLINE',
}

export interface IProductPrice {
  id: string;
  currency: string;
  price: number;
  retailer: IRetailer;
  unit_price: number;
  unit: string;
  valid_from: string;
  valid_till: string;
  note: string;
  location: string;
  page: ISourcePage;
  source_type: SourceTypesEnum;
  created_at: string;
  modified_at: string;
  origin: string;
  grade: string;
  num_packs: number;
  quantity: number;
  mt_size: string;
}

export interface IPriceResult {
  id: string;
  name: string;
  image: string;
  logo: string;
  location: string | null;
  prices: IProductPrice[] | null;
  currency: string;
  priceMode: number;
  priceMean: number;
  priceLowest: number;
  hasPromo: boolean;
  sourceType: string | null;
  isFav: boolean;
  isNew: boolean;
  isMustbuy: boolean;
  isSponsored: boolean;
  lowest?: IProductPrice;
}

export interface ICustomProduct {
  id: string;
  title: string;
  price: string;
  image: string;
  url: string;
  position: number;
}

export interface IPoll {
  id: string;
  user_property: string;
  question: {
    en: string;
    ms: string;
    zh: string;
  };
  multiple_answers: boolean;
  answers: [
    {
      en: string;
      ms: string;
      zh: string;
      property_value: string;
    },
  ];
}

export interface IGooglePlaceData {
  description: string;
  id: string;
  place_id: string;
}

export interface ILocation {
  city?: string;
  state: string;
  lat: number;
  long: number;
}

export interface IUserLocation {
  latitude: number;
  longitude: number;
  location_name: string;
  postcode?: string;
}

export enum SearchPageState {
  none = 'none',
  search = 'search',
  retailer = 'retailer',
  brand = 'brand',
  typeahead = 'typeahead',
}
