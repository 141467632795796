import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import Loader from "../components/Loader";

import { StaticImage } from "gatsby-plugin-image";

import Helmet from "react-helmet";

import { IProduct, IProductPrice, IRetailer } from "../@types/globals";
import _ from "underscore";
import * as dayjs from "dayjs";
import PriceHelper from "../util/PriceHelper";

// const EVENT_ID = "01CZ5GESFFWB2X70RYDYXCEY0R";
const EVENT_ID = "01G6YJSDE3E20MGC4D2K1BZ5K4";

const API = "https://api.askhargapedia.com/graphql";

const fetchEvent = async (id: string, retailers?: string[]) => {
  const query = `
  query GetEventDetailsForWeb($id: ID!, $retailers: [ID]) {
    event(id: $id, disable_targeting: true) {
      name {
        en
      }
      products {
        id
        short_name
        slug
        image {
          small
        }
        prices(retailers: $retailers){
          id
          retailer {
            id
            logo {
              center
            }
          }
          price
          currency
          location
          source_type
          modified_at
        }
      }
      retailers {
        id
        logo {
          center
        }
      }
    }
  }
  `;
  const variables = { id, retailers };
  const response = await fetch(API, {
    method: "POST",
    // mode: 'cors',
    headers: {
      "Content-Type": "application/json",
      "x-api-key": "5ba1d496-314f-4d3e-ad0e-4042ef8a49e9",
      "x-application-version": "minisite",
    },
    body: JSON.stringify({
      query,
      variables,
    }),
  });
  if (response.ok) {
    const { data, error } = await response.json();
    return { data, error };
  }
  return { data: null, error: Error("No response") };
};

export default function PriceDashboard() {
  const [products, setProducts] = useState([]);
  const [retailers, setRetailers] = useState([]);
  const [eventName, setEventName] = useState([]);

  const hpWebUrl = "https://www.hargapedia.com.my/product";

  const getLastUpdated = (products: IProduct[]): string => {
    // get the latest date out of all the prices
    const prices: IProductPrice[] = [];
    products.forEach((p) => {
      prices.push(...p.prices);
    });

    const dates = prices.map((p) => dayjs(p.modified_at).unix());
    const final = _.sortBy(dates).reverse();
    if (final && final.length > 0) {
      const date = final[0];
      return `${dayjs.unix(date).format("D MMM YYYY, hh:mmA")}`;
    }
    return "";
  };

  useEffect(() => {
    fetchEvent(
      EVENT_ID,
      retailers.length > 0 ? retailers.map((e) => e.id) : null
    ).then(({ data }) => {
      if (data && data.event) {
        setEventName(data.event.name);
        setProducts(data.event.products);
        if (retailers.length === 0) {
          setRetailers(data.event.retailers);
        }
      }
    });
  }, [retailers]);

  if (products.length === 0) {
    return <Loader />;
  }

  const getPricesForRetailers = (
    prices: IProductPrice[],
    retailers: IRetailer[]
  ) => retailers.map((r) => _.find(prices, (p) => p.retailer.id === r.id));

  const retailerHighlight = retailers.slice(0, 3).map((r: IRetailer) => ({
    id: r.id,
    name: r.name,
    url: r.logo.center,
  }));

  const productHighlight = products.slice(0, 3).map((p: IProduct) => {
    const { prices }: { prices: IProductPrice[] } = p;
    const result = PriceHelper.getLowestPriceMultiple(prices);

    return {
      id: p.id,
      slug: p.slug,
      short_name: p.short_name,
      imageUrl: p.image.small,
      lowest: result,
      prices: getPricesForRetailers(prices, retailers.slice(0, 3)),
      currency: p.prices && p.prices[0] ? p.prices[0].currency : "",
    };
  });

  return (
    <Layout pageName="price-widget">
      <Helmet>
        <title>Dashboard Widget</title>
      </Helmet>

      <div className="table-container">
        <div className="widget-header">
          <span className="widget-title">Top 50 Raya Essentials</span>
          {/* <span className="widget-title">{eventName.en}</span> */}
          {/* <a href="https://price.hargapedia.com.my" target="_blank">
            <StaticImage
              height={20}
              src="../images/logo-white.svg"
              alt="Hargapedia"
              placeholder="blurred"
            />
          </a> */}
          <a
            href="https://price.hargapedia.com.my/raya2022"
            target="_blank"
            className="btn-more"
          >
            SEE MORE{" "}
            <svg style={{ width: 20, height: 20 }} viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
              />
            </svg>
          </a>
        </div>
        <table className="table-widget">
          <thead>
            <tr>
              <th></th>
              <th className="col-cheapest header">CHEAPEST</th>
              {retailerHighlight.map((r) => {
                return (
                  <th key={r.id}>
                    <img src={r.url} alt={r.name} className="logo-retailer" />
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {productHighlight.map((p) => {
              const lowestPrice =
                p.lowest && p.lowest.length > 0 ? p.lowest[0] : null;

              return (
                <tr key={p.id}>
                  <td className="col-product">
                    <a href={`${hpWebUrl}/${p.slug}`} target="_blank">
                      <img
                        src={p.imageUrl}
                        alt={p.short_name}
                        className="product-thumbnail"
                      />
                    </a>
                  </td>
                  <td className="col-cheapest">
                    {lowestPrice ? (
                      <div className="price-cheapest">
                        {lowestPrice.price.toFixed(2)}
                      </div>
                    ) : null}

                    {lowestPrice ? (
                      <div className="item-retailer">
                        <img
                          src={lowestPrice.retailer.logo.center}
                          alt={lowestPrice.retailer.name}
                        />
                      </div>
                    ) : null}
                  </td>

                  {p.prices.map((pp, index) => {
                    if (!pp) {
                      return <td key={`${p.id}:${index}`}>-</td>;
                    }
                    return (
                      <td key={`${p.id}:${index}`}>
                        <div className="price-others">
                          {pp.price.toFixed(2)}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="misc">
          <div className="price">
            Prices in <span>RM</span>
          </div>
          <div className="updated">{getLastUpdated(products)}</div>
        </div>
      </div>
      {/* <div className="widget-cta">
        <div className="cta-title">
          <StaticImage
            placeholder="blurred"
            src="../images/ic-app.png"
            alt="Hargapedia"
            className="icon-app"
          />

          <h4>Your Essential Weekly Shopping App</h4>
        </div>
        <div className="btn-group">
          <a
            href="https://itunes.apple.com/us/app/hargapedia/id1315874967?ls=1&mt=8"
            target="_blank"
          >
            <StaticImage
              height={40}
              src="../images/btn-apple.svg"
              alt="Get Hargapedia - The grocery, health &amp; beauty, price comparison app from the Apple App Store"
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.askhargapedia.app"
            target="_blank"
          >
            <StaticImage
              height={40}
              src="../images/btn-google.svg"
              alt="Get Hargapedia - The grocery, health &amp; beauty, price comparison app from the Google Play Store"
            />
          </a>
          <a
            href="https://appgallery5.huawei.com/#/app/C102195777"
            target="_blank"
          >
            <StaticImage
              height={40}
              src="../images/btn-huawei.svg"
              alt="Get Hargapedia - The grocery, health &amp; beauty, price comparison app from Huawei AppGallery"
            />
          </a>
        </div>
      </div> */}
    </Layout>
  );
}
